import React from "react"
import Wrapper from "../components/Wrapper"
import { css } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import SignUp from "../components/SignUp"
import BigTitle from "../components/BigTitle"
interface Props {
  title: string
  description: string
  children: React.ReactNode | React.ReactNode[]
}

const LandingPage = ({ title, description, children }: Props) => {
  return (
    <Layout>
      <SEO title={title} description={description} />
      <Wrapper padding="4em">
        <BigTitle>Demos</BigTitle>
        {children}
      </Wrapper>
      <div
        css={css`
          width: 38em;
          height: 18em;
          margin: 0 auto;
          border-radius: 1em;
          overflow: hidden;
          margin-bottom: 4em;
        `}
      >
        <SignUp text="Sign Up Here!" />
      </div>
    </Layout>
  )
}

export default LandingPage

import React from "react"
import { css } from "@emotion/core"

import Landing from "../templates/Landing"
import ButtonLink from "../components/ButtonLink"
import Apple from "../components/icons/Apple"
import Windows from "../components/icons/Windows"
import Linux from "../components/icons/Linux"
import { FluidObject } from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Slideshow from "../components/Slideshow";

const downloads = [
  {
    download: "transmogrify_demo_windows.zip",
    href:
      "https://transmogrify-site-downloads.s3.amazonaws.com/demos/TransmogrifyDemoWindows.zip",
    platform: "Windows",
    Icon: Windows,
  },
  {
    download: "transmogrify_demo_linux.zip",
    href:
      "https://transmogrify-site-downloads.s3.amazonaws.com/demos/TransmogrifyDemoLinux.zip",
    platform: "Linux",
    Icon: Linux,
	disabled: true,
    subtitle: `Currently experiencing issues with our Linux build, will be ready soon!`,
  },
  /**/
  {
    download: "transmogrify_demo_macos.zip",
    href:
      "https://transmogrify-site-downloads.s3.amazonaws.com/demos/Transmogrify_MacOS_08072020.zip",
    platform: "MacOS",
    Icon: Apple,
    disabled: true,
    subtitle: `Currently experiencing issues with our Mac build, will be ready soon!`,
  },
  /**/
]

export default () =>{
  const {
    allFile: { edges },
  } = useStaticQuery<UrlProps>(graphql`
    query {
      allFile(
        sort: {fields: [base], order: ASC},
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "presskit" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
  <Landing title="Demos" description="Download the Transmogrify Demo!">
            <div
          css={css`
            height: 30em;
            width: 100%;
            position: relative;
          `}
        >
          <Slideshow edges={edges}/>
        </div>
    <div
      css={css`
        max-width: 900px;
        margin: 0 auto;
        display: flex;
        @media (max-width: 768px) {
          flex-direction: column;
        }
      `}
    >
      {downloads.map(({ download, href, platform, Icon, ...rest }) => (
        <div
          css={css`
            font-weight: 700;
            width: 350px;
          `}
        >
          <ButtonLink
            icon={Icon}
            iconSize={4}
            key={download}
            to={href}
            css={css`
              height: 6em;
              margin: 1em;
            `}
            {...rest}
          >
            {platform}
          </ButtonLink>
        </div>
      ))}
    </div>
    <p
      css={css`
        max-width: 900px;
        margin: 0 auto;
        text-align: center;
      `}
    >
      Note: If the above links are temporarily down, please visit our backups
      over at{" "}
      <a
        id="download-mirror"
        className="mirrors"
        css={css`
          color: inherit;
        `}
        href="https://odysseyentertainment.itch.io/transmogrify"
      >
        Itch.io
      </a>
    </p>
  </Landing>
)}

type UrlProps = {
  allFile: {
    edges: {
      node: {
        base: string
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }[]
  }
}
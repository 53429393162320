import React, { SVGProps } from "react"

export default ({ fill = "#ffffff" }: SVGProps<SVGGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 88 88">
    <g fill={fill}>
    <path
      d="m0,12.402,35.687-4.8602,0.0156,34.423-35.67,0.20313zm35.67,33.529,0.0277,34.453-35.67-4.9041-0.002-29.78zm4.3261-39.025,47.318-6.906,0,41.527-47.318,0.37565zm47.329,39.349-0.0111,41.34-47.318-6.6784-0.0663-34.739z"
      />
      </g>
  </svg>
)
